import React from 'react';
import css from './ProfilePict.module.css';

const ProfilePict = (props) => (
	<div {...props} className={css.ProfileContainer}>
		<img
			src={props.src}
			alt="Profile"
			className={[
				css.Profile,
				props.logoMode && (css.Profile + '_' + props.logoMode),
			].join(' ')}
			/>
	</div>
);
export default ProfilePict;

import React from 'react';
import { useParams } from 'react-router';

import ReviewPage from '../Review.page';
import { reviewPrefix } from '../../../util/lib';

const OpeningPage = (props) => {
  const {code} = useParams();
  const {api, legacy, review} = props;

  const textContent = <div>How was your experience with us?</div>;
  const choose = (overall) => async () => {
    try {
      if (!review) return;
      await api({
        method: 'put',
        responseType: 'json',
        url: `/reviews/${review._id}`,
        data: {
          overall,
          partnerId: review.partnerId,
        }
      });
    } catch (error) {
      console.error(error);
    }
  };
  const prefix = reviewPrefix(legacy);
  if (review) {
    const {partner} = review;
    if (partner && partner.ignoreExperience) {
      return 'loading...';
    }
  } else {
    return 'loading...';
  }
  return (
    <ReviewPage
      reviewLogo="0"
      buttonType="thumbs"
      textContent={textContent}
      onSuccess={choose('good')}
      onFallback={choose('bad')}
      successPage={`${prefix}/${code}/tree`}
      fallbackPage={`${prefix}/${code}/review-form-bad`}
    />
  );
};
export default OpeningPage;

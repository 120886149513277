import React from 'react';
import css from './Input.module.css';

const Input = (props) => {
  const { form, rule } = props;
  const { formState: { errors } } = form;
  return (
    <div className={[
      css.InputContainer,
      props.className
    ].join(' ')}>
      <input
        {...form.register(props.name, rule)}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        name={props.name}
        placeholder={props.placeholder}
        type={props.type}
        defaultValue={props.defaultValue}
        className={[
          css.TextBox,
          props.className,
          errors && errors[props.name] && css.InputError,
          props.bottomBordered && css.TextBoxBottomBordered
        ].join(' ')}
        maxLength={props.maxLength}
        disabled={props.disabled}
      />
      {errors && errors[props.name] && (
        <div className={css.TextWarning}>
          {errors[props.name].message}
        </div>
      )}
    </div>
  );
};

export default Input;

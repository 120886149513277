import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';

import { useApi } from '../../util/lib';
import Button from '../UI/Buttons/Button';
import { delay } from '../../util/ExtraMethods';

import css from './NotificationUnsubs.module.css';

const scopes = [
  ['booking', 'Booking'],
  ['account', 'Account'],
  ['service-reminder', 'Service Reminder'],
]

const NotificationUnsubs = () => {
  const api = useApi();
  const {id} = useParams();
  const history = useHistory();
  const [selected, setSelected] = useState([]);
  const [submitting, setSubmitting] = useState();
  const form = useForm({
    defaultValues: {
      all: null,
      scopes: [],
    }
  });

  const onChange = (e) => {
    const value = e.target.value + '';
    const isChecked = !!e.target.checked;
    let newSelected;
    console.log('onChange', {value, isChecked});
    if (isChecked) {
      newSelected = [...selected, value]
    } else {
      newSelected = selected.filter(item => item !== value);
      console.log('onChange all reset', value);
      form.setValue('all', null);
    }
    setSelected(newSelected);
  }
  const onChangeAll = (e) => {
    const isAll = !!e.target.checked;
    console.log('onChangeAll', isAll);
    setSelected(
      isAll ? scopes.map(scope => scope[0]) : []
    );
  }
  const onSubmit = form.handleSubmit(async (data) => {
    try {
      if (submitting) return;
      setSubmitting(true);
      await delay(2000);

      if (!data.all) delete data.all;
      console.log('onSubmit', data);
      const response = await api({
        data,
        method: 'put',
        responseType: 'json',
        url: `/customers/${id}/unsubscribe`,
      });
      console.log('response', response);
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
      history.push('/unsubscribed');
    }
  });

  return (
    <div className={css.Container}>
      <h2>Unsubscribe from Repairy</h2>
      <div className={css.SelectAll}>
        <input
          value='all'
          type='checkbox'
          id='form_scope_all'
          className={css.SelectAll}
          {...form.register('all')}
          onChange={onChangeAll}
          checked={selected.length === scopes.length}
        />
        <label htmlFor='form_scope_all'>
          Turn off all notifications
        </label>
      </div>
      <div className={css.Grid}>
        {scopes.map((scope, idx) => {
          const id = `form_scope_${scope}_${+new Date()}`;
          return (
            <div key={idx} className={css.Scope}>
              <input
                id={id}
                type='checkbox'
                value={scope[0]}
                {...form.register('scopes')}
                onChange={onChange}
                checked={selected.some(value => value === scope[0])}
              />
              <label htmlFor={id}>{scope[1]}</label>
            </div>
          );
        }
        )}
      </div>
      <p>
        We will no longer send you notification 
        in a form of email nor SMS
        based on your configuration here.
        Please recheck and submit.
      </p>
      <Button
        type='main'
        onClick={onSubmit}
        disabled={submitting}
        css={[
          submitting && css.SubmitDisabled,
        ].join(' ')}>
        {submitting ? 'Submitting...' : 'Submit'}
      </Button>
    </div>
  )
}
export default NotificationUnsubs;

import React from 'react';

import NotificationUnsubs from './NotificationUnsubs';
import css from './NotificationUnsubs.module.css';

export const UnsubscribeDone = () => {
  return (
    <div className={css.Container}>
      <h3>Unsubscribe completed</h3>
      <p>Thank you for using Repairy</p>
      <p>
        Please <a href='mailto:support@repairy.com.au'>contact support</a> 
        &nbsp;if you want to reactivate your Repairy notifications.
      </p>
    </div>
  );
}

export default NotificationUnsubs;

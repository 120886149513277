import React from 'react';
import { useParams } from 'react-router';

import { referralRedeemPath } from '../../../util/lib';
import ReferralPage from '../ReferralPage/Referral.page';

const OpeningPage = ({ referral, shop }) => {
  const {code} = useParams();
  if (!referral || !shop) {
    return <div>Loading...</div>;
  }
  const textContent = (
    <div>
      {referral.name} wants to share a free coffee at {shop.name} with you.
    </div>
  );
  const successPage = referralRedeemPath(code, 'form');
  return (
    <ReferralPage
      title='Good News'
      textContent={textContent}
      successPage={successPage}
      successButton='Start Redeeming'
    />
  );
};
export default OpeningPage;

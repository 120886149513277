import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import HtmlHead from '../UI/Head/HtmlHead';
import { RepairyBookingURL, RepairyBookingV2URL, useApi } from '../../util/lib';

export const ShortCodeBooking = () => {
  const [pristine, setPristine] = useState(true)
  const [error, setError] = useState()
	const api = useApi();
  const { id, code } = useParams();
  useEffect(() => {
    const checkVersion = async () => {
      try {
        if (!pristine) return
        setPristine(false)
        const response = await api({
          method: 'get',
          responseType: 'json',
          url: `/sdk/${id}/config-version`,
        });
        let suffix = []
        let baseURL = RepairyBookingURL
        if (response.data && response.data.configVersion === 'v2') {
          baseURL = RepairyBookingV2URL
          suffix.push('/status')
        }
        // redirect
        const uris = [baseURL, '/', id, '/', code, ...suffix];
        window.location.href = uris.join('');
      } catch (error) {
        console.error(error)
        setError(error)
      }
    }
    checkVersion();
  }, [api, code, id, pristine])
  return (
    <>
      <HtmlHead repairy />
      <p style={{
                padding: '10px 50px',  
              }}>
        {
          error
            ? <>
                Opps! Seems like we cant provide you a service now. 
                Please contact support if problem persists
              </> 
            : <>Redirecting...</>
        }
      </p>
    </>
  );
}

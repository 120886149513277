import React from 'react';
import { useParams } from 'react-router-dom';

import HtmlHead from '../UI/Head/HtmlHead';
import { useRedirectWithConfigVersion } from '../../hooks/useRedirectWithConfigVersion';

export const BookingRequest = () => {
  const { id, code } = useParams();
  const { error } = useRedirectWithConfigVersion({
    id,
    context: 'dashboard',
    uris: [
      '/bookings/requests/',
      id,
      '/',
      code,
    ]
  });
  return (
    <>
      <HtmlHead repairy />
      <p style={{
                padding: '10px 50px',  
              }}>
        {
          error
            ? <>
                Opps! Seems like we cant provide you a service now. 
                Please contact support if problem persists
              </> 
            : <>Redirecting...</>
        }
      </p>
    </>
  );
}

import { useParams } from 'react-router';
import React, { useEffect } from 'react';

import Content from '../../UI/Content/Content';
import TextContainer from '../../UI/Content/TextContainer';
import { delay, getLink } from '../../../util/ExtraMethods';
import { ReactComponent as FbLogo } from '../../../components/UI/Images/fb-logo.svg';
import { ReactComponent as GoogleLogo } from '../../../components/UI/Images/google-icon.svg';
import css from './RedirectFlytree.module.css';

const RedirectFlytree = (props) => {
  const {reviewLinks} = props;
	let { platform } = useParams();
	let icon = null;
	let target = ''
	let redirectTo = null;
	switch (platform) {
		case 'google':
			target = 'to Google';
			icon = <GoogleLogo />;
			redirectTo = getLink('google', reviewLinks);
			break;
		case 'facebook':
			target = 'to Facebook';
			icon = <FbLogo />;
			redirectTo = getLink('fb', reviewLinks);
			break;
		default:
			icon = <GoogleLogo />;
			redirectTo = getLink('google', reviewLinks);
			break;
	}
  useEffect(() => {
    const redirect = async () => {
			const link = redirectTo || {};
			// smart URL from integration
			const url = link.smartURL || link.url;
			if (url) {
				await delay(500);
				window.location.href = url.replace(/[^\x20-\x7E]/g, '');
			}
		}
		redirect();
	}, [redirectTo]);
	return (
		<Content>
			<div className={css.Icon}>
				<div className={css.Backdrop}></div>
				<div className={css.IconContainer}>{icon}</div>
				<TextContainer className={css.TextContainer}>
					Hold tight, while we take you to {target} to leave a review.
				</TextContainer>
			</div>
		</Content>
	);
};
export default RedirectFlytree;

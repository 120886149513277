import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import Button from '../../UI/Buttons/Button';
import Content from '../../UI/Content/Content';
import { reviewPrefix } from '../../../util/lib';
import ProfilePict from '../../UI/ProfilePict/ProfilePict';
import TextContainer from '../../UI/Content/TextContainer';
import { AppContext } from '../../../containers/AppContext';
import { delay, getLink } from '../../../util/ExtraMethods';
import { ReactComponent as FbLogo } from '../../../components/UI/Images/fb-logo.svg';
import { ReactComponent as GoogleLogo } from '../../../components/UI/Images/google-icon.svg';

import css from './ReviewTree.module.css';

const ReviewTree = (props) => {
	const { api, legacy, review, reviewLinks } = props;
	const { code } = useParams();
	const history = useHistory();
	const [pageState, setPageState] = useState(1);
	const { shop = {} } = useContext(AppContext);

	const prefix = reviewPrefix(legacy);
	const googlePage = `${prefix}/${code}/redirect/google`;
	const fbPage = `${prefix}/${code}/redirect/facebook`;
	
	const track = async (channel) => {
		try {
			if (!channel) return;
			await api({
				method: 'put',
				responseType: 'json',
				url: `/reviews/${review._id}`,
				data: {
					channel,
					reviewedAt: new Date(),
					partnerId: review.partnerId,
				},
			});
		} catch (error) {
			console.error(error);
		}
	};
	const movePage = async (nextPage, channel) => {
		setPageState(0);
		await delay(400);
		if (channel) {
			await track(channel);
		}
		history.push(nextPage);
	};

	const googleLink = getLink('google', reviewLinks);
	const fbLink = getLink('fb', reviewLinks);
	const noLink = !googleLink && !fbLink;

	if (noLink) return (
		<Content state={pageState}>
			<TextContainer className={css.ReviewTree}>
				{shop && shop.logo && (
					<ProfilePict
						src={shop.logo}
						type={shop.logoMode} />
				)}
			</TextContainer>
			<p>We are in the midst of creating new things</p>
			<p>Please come back later</p>
		</Content>
	);

	return (
		<Content state={pageState}>
			<TextContainer className={css.ReviewTree}>
				{shop && shop.logo && (
					<ProfilePict
						src={shop.logo}
						type={shop.logoMode} />
				)}
				Would you take a moment to leave us a review
				<div className={css.ButtonTree}>
					{googleLink && (
						<Button onClick={() => movePage(googlePage, 'google')}>
							<div>
								<GoogleLogo />
							</div>
							Review us on Google
						</Button>
					)}
					{fbLink && (
						<Button onClick={() => movePage(fbPage, 'fb')}>
							<div>
								<FbLogo />
							</div>
							Review us on Facebook
						</Button>
					)}
				</div>
			</TextContainer>
		</Content>
	);
};
export default ReviewTree;
